import React, { FunctionComponent, useEffect, useState } from 'react'
import axios from 'axios'
import { beamerInitialize } from 'react-beamer'
import { Route, Routes } from 'react-router'
import { Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/css/styles.css'
import avatar from '../assets/img/avatar-1.jpg'
import { defaultAuthHandler } from '../auth'
import { UserProvider } from '../auth/UserContext'
import AccessFrame from '../components/Access/AccessFrame'
import { AccessConfig } from '../components/Access/Data'
import EndAccessModal from '../components/Access/EndAccessModal'
import { ContactSupport } from '../components/ContactSupport/ContactSupport'
import { Reseller } from '../components/ContactSupport/Data'
import DemoTeamModal from '../components/DemoTeamModal/DemoTeamModal'
import Header from '../components/Header'
import { AddCustomerFunction, EditCustomerFunction, HostData } from '../components/Host/Data'
import { HostSlideout } from '../components/HostSlideout/HostSlideout'
import InfoCenter from '../components/InfoCenter'
import ManageMultiverse from '../components/Management/ManageMultiverse'
import { MyHostsContainer } from '../components/MyHosts/MyHostsContainer'
import NavBar from '../components/NavBar'
import ServiceProviders from '../components/ServiceProviders'
import { PartnerData } from '../components/ServiceProviders/ServiceProviderInviteModal'
import MyTeamContainer from '../components/Team/MyTeamContainer'
import { TeamSettingsData } from '../components/TeamSettings/TeamSettings'
import UserSettings from '../components/UserActions/UserSettings'
import { Features } from '../features'
import { useMountEffect } from '../hooks/MountEffect'
import { useTypedSelector } from '../redux/reducers'
import { isDemo, isFeatureOn } from '../redux/selectors'
import { SlideoutState } from '../redux/slideout'
import AuthRole from '../role'
import { Timestamps } from '../service/Timestamps'

interface Props {
  teamID: string
  team: TeamSettingsData
  hosts: HostData[]
  slideout?: SlideoutState
  activeAccessConfig?: AccessConfig
  isAccessFrameActive: boolean
  confirmingCloseFrame: boolean
  addCustomer: AddCustomerFunction
  editCustomer: EditCustomerFunction
  deleteCustomer: (id: string) => void
  openAccessFrame: (config: AccessConfig) => void
  closeConfirmed: () => void
  exitConfirmClosePrompt: () => void
  openConfirmClosePrompt: () => void
  parents: Reseller[]
  modifyTeamSettings: (newSettings: TeamSettingsData) => Promise<boolean>
  partners: PartnerData[]
}

interface Migration {
  active: boolean
  region: string
}

const App: FunctionComponent<Props> = props => {
  AuthRole.setPermissions(defaultAuthHandler.getPermissions())
  const [migration, setMigration] = useState<Migration>({ active: false, region: '' })
  const showPropsFilter = useTypedSelector(r => r.filter.showFilterPanel)
  const demoMode = useTypedSelector(isDemo)
  const inviteServiceProvidersEnabled = useTypedSelector(isFeatureOn(Features.InviteServiceProviders))
  const showManageMultiverseMenu = useTypedSelector(isFeatureOn(Features.ManageMultiverse)) && !demoMode
  const [showDemoModal, setShowDemoModal] = useState(demoMode)

  useMountEffect(() => {
    beamerInitialize('aYBpvSvn39780', '/js/beamer-embed.js', {
      selector: '.beamerTrigger',
      user_email: defaultAuthHandler.getUserName(),
      user_id: defaultAuthHandler.getUserID(),
    })
  })

  useEffect(() => {
    axios
      .get('/api/teams/' + props.teamID + '/migration')
      .then(resp => {
        setMigration(resp.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [props.teamID])

  const userName = defaultAuthHandler.getUserName()
  const picture = defaultAuthHandler.getUserPicture() !== '' ? defaultAuthHandler.getUserPicture() : avatar
  const user = { userName: userName, profilePicture: picture }
  const accessToken = defaultAuthHandler.getToken()?.accessToken ?? ''

  const asideClass = props.isAccessFrameActive || props.slideout?.host ? 'open-aside' : ''

  const isPartnerTeam = (teamID: string): boolean => {
    for (let partner of props.partners) {
      if (partner.teamID === teamID) {
        return true
      }
    }
    return false
  }
  const showServiceProvidersMenu = isPartnerTeam(props.teamID) && inviteServiceProvidersEnabled

  return (
    <UserProvider value={user}>
      <div className={`ready wrap ${asideClass} ${showPropsFilter ? 'open-filter' : ''}`}>
        {migration.active && (
          <div className='migrating'>
            <div className='auto flex flex-ver flex-nowrap'>
              <div className='message center'>
                Your dashboard has been migrated to your requested region. To access the new dashboard, you <strong>must log out</strong> and follow the provided migration
                instructions.
              </div>
            </div>
          </div>
        )}

        <Header numberOfCustomers={props.hosts === null ? 0 : props.hosts.length} team={props.team} teamID={props.teamID} parents={props.parents} />
        <main>
          <NavBar showServiceProviders={showServiceProvidersMenu} showManageMultiverse={showManageMultiverseMenu} />
          {demoMode && (
            <>
              <div style={{ backgroundColor: '#f47900', padding: '0.5rem 0.5rem', margin: '0px -30px', color: '#fff' }}>
                <div className='flex flex-ver flex-nowrap'>
                  <div className='message center'>For demo purposes. Functionality may be limited.</div>
                </div>
              </div>
              {showDemoModal && <DemoTeamModal onClose={() => setShowDemoModal(false)} />}
            </>
          )}
          <div id='page'>
            <Routes>
              <Route
                path='/my-customers/*'
                element={
                  <MyHostsContainer
                    teamID={props.teamID}
                    addCustomer={props.addCustomer}
                    deleteCustomer={props.deleteCustomer}
                    editCustomer={props.editCustomer}
                    hosts={props.hosts}
                    openAccessFrame={props.openAccessFrame}
                    migrating={migration.active}
                  />
                }
              />
              <Route
                path='/my-team/*'
                element={
                  <MyTeamContainer
                    teamID={props.teamID}
                    team={props.team}
                    modifyTeamSettings={props.modifyTeamSettings}
                    numberOfCustomers={props.hosts.length || 0}
                    parents={props.parents}
                  />
                }
              />
              <Route path='/info-center' element={<InfoCenter />} />
              <Route path='/user-settings' element={<UserSettings accessToken={accessToken} />} />
              {showServiceProvidersMenu && <Route path='/service-providers' element={<ServiceProviders teamID={props.teamID} />} />}
              {showManageMultiverseMenu && <Route path='/manage/*' element={<ManageMultiverse teamID={props.teamID} />} />}
              <Route path='*' element={<Navigate to='/my-customers/list' replace />} />
            </Routes>
          </div>
        </main>

        <footer id='footer-app'>
          <div className='auto flex'>
            <p>
              © PaperCut Software Pty Ltd. All times are {Timestamps.zone()}.
              <br />
              <a href='/tos.html' target='_blank'>
                Terms of Service
              </a>{' '}
              ·{' '}
              <a href='https://www.papercut.com/privacy-policy/' target='_blank' rel='noopener noreferrer'>
                Privacy Policy
              </a>
            </p>
          </div>
        </footer>

        {props.confirmingCloseFrame && <EndAccessModal onYes={props.closeConfirmed} onCancel={props.exitConfirmClosePrompt} />}

        {props.activeAccessConfig && <AccessFrame config={props.activeAccessConfig} close={props.openConfirmClosePrompt} closeWithoutConfirmation={props.closeConfirmed} />}

        {props.slideout?.host && (
          <HostSlideout teamID={props.teamID} host={props.slideout.host} userName={userName} editCustomer={props.editCustomer} startTab={props.slideout.startTab} />
        )}
      </div>

      <ContactSupport contacts={[]} />

      <ToastContainer />
    </UserProvider>
  )
}

export default App

import React, { FunctionComponent, useState } from 'react'
import axios, { AxiosError } from 'axios'
import { useNavigate } from 'react-router'
import '../../assets/css/css/styles.css'
import mvPoster from '../../assets/img/mv.png'
import { GetDataCenterRegionString } from '../../env'
import { useMountEffect } from '../../hooks/MountEffect'
import { HttpStatus } from '../../service/HttpStatus'
import { supportEmail } from '../ContactSupport/Data'
import { AnyEnvironment, ExclaimIcon, Insights, RemoteRocket } from '../Icons/Icons'
import TourModal from '../TourModal/TourModal'
import styles from './LandingPage.module.css'

const LandingPage: FunctionComponent = () => {
  const [showTour, setShowTour] = useState(false)
  const [error, setError] = useState<React.ReactNode>('')
  const hour = new Date().getHours()

  useMountEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    let errorParam = urlSearchParams.get('err')
    if (errorParam) {
      setError(errorParam.replace('\\n', ''))
    }
    let errorCode = urlSearchParams.get('err_code')
    if (errorCode) {
      switch (errorCode) {
        case 'WRONG_DATA_CENTER':
          let regions: { [key: string]: string } = {
            US: 'https://multiverse.papercut.com',
            EU: 'https://eu.multiverse.papercut.com',
            UK: 'https://uk.multiverse.papercut.com',
            AU: 'https://au.multiverse.papercut.com',
          }
          delete regions[GetDataCenterRegionString()]
          setError(
            <>
              <p>Your account was not found in the {GetDataCenterRegionString()} Data Center.</p>
              <p>Try logging in to your regional Data Center at one of the following links or check your invitation email for the link:</p>
              <p>
                {Object.entries(regions).map(([region, baseLink], index, me) => (
                  <React.Fragment key={region}>
                    <a href={baseLink + '/landing?rsn=dcredirect'}>{region}</a>
                    {index !== me.length - 1 ? <span className={styles.divider}>|</span> : ''}
                  </React.Fragment>
                ))}
              </p>
            </>
          )
          break
        default:
          setError(<>Unknown error: {errorCode}</>)
      }
    }

    let inviteCode = urlSearchParams.get('team-invite-code')
    if (inviteCode) {
      axios
        .get('/api/invite-email?inviteCode=' + inviteCode)
        .then(_ => {})
        .catch((e: AxiosError) => {
          if (e.response!.status === HttpStatus.Unauthorized) {
            setError('Your invitation is older than 7 days and has expired. Contact your Admin to request a new invitation.')
          }
          if (e.response!.status === HttpStatus.NotFound) {
            setError('Your invitation has been revoked. Contact your Admin for more information.')
          }
        })
    }
  })

  const navigateTo = useNavigate()
  function handleContinue() {
    navigateTo('/' + window.location.search)
  }

  return (
    <div className='page-login mash ready open-start'>
      <main>
        <div id='page' className='auto'>
          <div className='bind'>
            <div id='form' className='col'>
              <div className='sticky'>
                <form>
                  <div id='logo'>
                    <a>PaperCut Multiverse. One console, all your customers.</a>
                  </div>

                  <h2 className='center'>
                    Good <span className='tod'>{hour < 12 ? `morning,` : hour < 18 ? `afternoon,` : `evening,`}</span>
                  </h2>

                  <p className='center'>log in to start exploring</p>

                  {error && (
                    <div className='alert error pullout'>
                      <i className='icon-exclaim'>
                        <ExclaimIcon />
                      </i>

                      <div className='message'>{error}</div>
                    </div>
                  )}

                  <p className='center'>
                    <button className={`btn center ${error ? 'disabled' : ''}`} onClick={() => handleContinue()} disabled={error !== ''}>
                      Continue
                    </button>
                  </p>

                  <small>
                    <a href={`mailto:${supportEmail}?subject=Request access to PaperCut Multiverse`} className='action'>
                      Contact us
                    </a>{' '}
                    to request access
                  </small>
                </form>

                <small>
                  <a href='/tos.html' target='_blank' rel='noopener noreferrer' className='action'>
                    Terms of Service
                  </a>{' '}
                  ·{' '}
                  <a href='https://www.papercut.com/privacy-policy/' target='_blank' rel='noopener noreferrer' className='action'>
                    Privacy Policy
                  </a>
                </small>
              </div>
            </div>

            <div id='pitch' className='col'>
              <div className='fh'>
                <section id='features'>
                  <div className='flex flex-col fh center flex-nowrap'>
                    <header>
                      <h1 id='build-trust-alt'>Remotely manage printing for all your customers</h1>

                      <div className='browser'>
                        <span className='close' />

                        <span className='shrink' />

                        <span className='expand' />

                        <video aria-describedby='build-trust-alt' poster={mvPoster} autoPlay loop muted playsInline>
                          <source src='https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/mv.mp4' type='video/mp4' />
                        </video>
                      </div>
                    </header>

                    <ul className='flex row'>
                      <li>
                        <i className='icon thirtytwo'>
                          <AnyEnvironment />
                        </i>

                        <div>
                          <p>Connect to all your customers in any environment, across our portfolio</p>
                        </div>
                      </li>

                      <li>
                        <i className='icon thirtytwo'>
                          <RemoteRocket />
                        </i>

                        <div>
                          <p>Proactively solve customer issues with remote monitoring and management</p>
                        </div>
                      </li>

                      <li>
                        <i className='icon thirtytwo'>
                          <Insights />
                        </i>

                        <div>
                          <p>Gain insight into how your customers use PaperCut products and how they can level up</p>
                        </div>
                      </li>
                    </ul>

                    <small className='flex'>
                      <span>
                        <a href='#' className='tour action' onClick={() => setShowTour(true)}>
                          Take the tour
                        </a>{' '}
                        to learn more.
                      </span>

                      <span className='rgt'>© PaperCut Software Pty Ltd</span>
                    </small>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>

      {showTour && <TourModal onClose={() => setShowTour(false)} />}
    </div>
  )
}

export default LandingPage

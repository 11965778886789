import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useMountEffect } from '../../hooks/MountEffect'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'
import { supportEmail } from '../ContactSupport/Data'
import { CustomerFunction, HostData, Product } from '../Host/Data'
import {
  CircledPlusIcon,
  ClipboardIcon,
  ExclaimIcon,
  HeroIcon,
  HyperlinkIcon,
  InfoIcon,
  LightningIcon,
  LinkCustomerIcon,
  MagicIcon,
  PeopleManIcon,
} from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import CustomModal from '../MyModal/CustomModal'
import { getPmitcDcs, getPmitcDcDisplayName } from '../Pmitc/Data'
import Toaster from '../Toaster'

interface CustomerOptionsItem {
  label: string
  value: string
}

interface Props {
  teamID: string
  isVisible: boolean
  close: () => void
  action: CustomerFunction
  host?: HostData
  addTag?: boolean
}

const infrastructureTypes: Record<string, string> = {
  aws: 'AWS',
  azure: 'Azure',
  gcp: 'Google Cloud Platform',
  papercut: 'PaperCut Cloud Services',
  local: 'Locally hosted',
}

const CustomerModal: FunctionComponent<Props> = props => {
  const demoMode = useTypedSelector(isDemo)
  const [hiveProvisioningAuthorized, setHiveProvisioningAuthorized] = useState(false)
  const [operationInProgress, setOperationInProgress] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [customerName, setCustomerName] = useState<string>(props.host?.name || '')
  const [newTag, setNewTag] = useState('')
  const [tags, setTags] = useState<Set<string>>(new Set<string>(props.host?.tags || []))
  const [product, setProduct] = useState(props.host?.product || Product.MF)
  const tagInputRef = useRef<HTMLInputElement>(null)
  const [tagValid, setTagValid] = useState(false)
  const [existingHiveOrg, setExistingHiveOrg] = useState(false)
  const [hiveOrgId, setHiveOrgId] = useState('')
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const [industriesList, setIndustriesList] = useState<CustomerOptionsItem[]>([])
  const [countriesList, setCountriesList] = useState<CustomerOptionsItem[]>([])
  const [hasCrn, setHasCrn] = useState(false)
  const [crn, setCrn] = useState('')
  const [hasDifferingNames, setHasDifferingNames] = useState(false)
  const [hiveOrgName, setHiveOrgName] = useState('')
  const [customerIndustry, setCustomerIndustry] = useState('')
  const [customerWebsite, setCustomerWebsite] = useState('')
  const [isSearchingForCountry, setIsSearchingForCountry] = useState(false)
  const [countrySearchTerm, setCountrySearchTerm] = useState('')
  const minCountrySearchTermLength = 2
  const addProvisionedHiveCustomerDisabled = !existingHiveOrg && !hiveProvisioningAuthorized
  const addCustomerDisabled = product === Product.Hive && (demoMode || addProvisionedHiveCustomerDisabled)
  const pmitcDcs = getPmitcDcs()
  const [pmitcDc, setPmitcDc] = useState(pmitcDcs[0])
  const isEditing = props.host !== undefined
  const nameRef = useRef<HTMLInputElement>(null)

  const customerCountry: CustomerOptionsItem = countriesList.find(c => c.label === countrySearchTerm) || {
    label: '',
    value: '',
  }
  const [filteredCountries, setFilteredCountries] = useState<CustomerOptionsItem[]>([])

  const fetchOptions = useCallback(async () => {
    setIsLoadingOptions(true)

    try {
      const industriesResponse = await axios.get<{ options: CustomerOptionsItem[] }>(
        `/api/teams/${props.teamID}/customer-options/industry`
      )

      if (industriesResponse.data.hasOwnProperty('options') && industriesResponse.data.options.length > 0) {
        setIndustriesList(industriesResponse.data.options.sort((a, b) => a.label.localeCompare(b.label)))
      }

      const countriesResponse = await axios.get<{ options: CustomerOptionsItem[] }>(
        `/api/teams/${props.teamID}/customer-options/country`
      )

      if (countriesResponse.data.hasOwnProperty('options') && countriesResponse.data.options.length > 0) {
        setCountriesList(countriesResponse.data.options.sort((a, b) => a.label.localeCompare(b.label)))
      }
    } catch (err) {
      console.error(err)

      Toaster.notifyFailureContent(
        <span>
          Unable to load customer options.
          <br />
          If this persists, contact{' '}
          <a href={`mailto:${supportEmail}?subject=PaperCut Hive org customer options fail to load`}>
            PaperCut Multiverse support
          </a>
          .
        </span>
      )
    }

    setIsLoadingOptions(false)
  }, [props.teamID])

  useMountEffect(() => {
    nameRef.current?.focus()
  })

  useEffect(() => {
    const set = new Set<CustomerOptionsItem>()
    if (countrySearchTerm.length >= minCountrySearchTermLength) {
      const term = countrySearchTerm.toLowerCase()
      countriesList.filter(c => c.label.toLowerCase().startsWith(term)).forEach(c => set.add(c))
      countriesList.filter(c => c.label.toLowerCase().includes(term, 1)).forEach(c => set.add(c))
    }
    setFilteredCountries(Array.from(set))
  }, [countriesList, countrySearchTerm])

  useEffect(() => {
    if (isEditing || product !== Product.Hive) return

    fetchOptions().then()
  }, [fetchOptions, product, isEditing])

  const initInfrastructure = () => {
    const isOther = props.host && props.host.infrastructure && !infrastructureTypes[props.host.infrastructure]

    return isOther ? 'other' : props.host?.infrastructure ?? ''
  }

  const [infrastructure, setInfrastructure] = useState(initInfrastructure)
  const isOtherInfrastructure = infrastructure && !infrastructureTypes[infrastructure]

  const initOtherInfrastructure = () => {
    if (props.host) {
      return infrastructureTypes[props.host.infrastructure] ? '' : props.host.infrastructure
    }

    return ''
  }

  const [otherInfrastructure, setOtherInfrastructure] = useState(initOtherInfrastructure)

  useEffect(() => {
    if (props.addTag) {
      tagInputRef.current?.focus()
    }
  }, [props.addTag])

  const onClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    props.close()
  }

  const addCustomer = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()

    if (!validate()) return

    setOperationInProgress(true)
    props
      .action(
        product,
        {
          name: customerName.trim(),
          infrastructure: isOtherInfrastructure ? otherInfrastructure : infrastructure,
          product: product,
          tags: Array.from(tags.values()),
          existingOrg: existingHiveOrg,
          orgId: hiveOrgId,
          dataCenter: pmitcDc,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          crn: hasCrn ? crn : '',
          customer: {
            name: hasDifferingNames ? hiveOrgName.trim() : customerName.trim(),
            industry: customerIndustry,
            website: customerWebsite,
            country: customerCountry.value,
          },
        },
        props.host?.id
      )
      .then(success => {
        setOperationInProgress(false)
        if (success) onClose(event)
      })
      .catch(_ => setOperationInProgress(false))
  }

  const validate = () => {
    const errorsFound = []
    if (!customerName || customerName.trim().length <= 0) {
      errorsFound.push('A display name is required')
    }
    if (!product) {
      errorsFound.push('You must select a product')
    }
    if (!infrastructure) {
      errorsFound.push('You must select the infrastructure environment')
    }
    if (infrastructure === 'other' && (!otherInfrastructure || otherInfrastructure.trim().length <= 0)) {
      errorsFound.push('You must enter the infrastructure environment')
    }
    if (!isEditing && product === Product.Hive) {
      if (existingHiveOrg) {
        if (!hiveOrgId) {
          errorsFound.push('A PaperCut Hive org ID is required')
        }
      } else {
        if (hasCrn) {
          if (!crn) {
            errorsFound.push('You must provide the CRN for the customer')
          }
        } else {
          if (hasDifferingNames && !hiveOrgName.trim()) {
            errorsFound.push('You must enter the organization name for the new customer')
          }
          if (!industriesList.some(i => i.value === customerIndustry)) {
            errorsFound.push('You must select the industry of the new customer')
          }
          if (!customerWebsite) {
            errorsFound.push('You must provide the website for the new customer')
          }
          if (!customerCountry.value) {
            errorsFound.push('You must select the country of the new customer')
          }
        }
      }
    }
    setErrors(errorsFound)

    return errorsFound.length <= 0
  }

  const handleProductChange = (e: React.MouseEvent<HTMLLIElement>, newProduct: string) => {
    e.preventDefault()
    setProduct(newProduct)
    if (newProduct === Product.Hive) {
      setInfrastructure('papercut')
    } else if (infrastructure === 'papercut') {
      setInfrastructure('')
    }
  }

  const getInfrastructureClass = (inf: string) => {
    if (inf === 'papercut') {
      return product === Product.Hive ? 'active' : 'hide'
    }
    if (product === Product.Hive) {
      return 'hide'
    }
    if (inf === infrastructure) {
      return 'active'
    }

    return ''
  }

  const getProductClass = (prod: string) => {
    let className = ''
    if (prod.toLowerCase() === product.toLowerCase()) {
      className = 'active'
    } else if (isEditing) {
      className = 'disabled'
    }

    return className
  }

  const addTag = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    const newTags = tags
    newTags.add(newTag.trim())
    setTags(newTags)
    setNewTag('')
  }

  const removeTag = (tag: string) => {
    const newTags = new Set<string>(tags.values())
    newTags.delete(tag)
    setTags(newTags)
  }

  const infrastructureSelection = (key: string, linkClass?: string) => {
    return (
      <li
        data-option={key}
        className={getInfrastructureClass(key)}
        onClick={e => {
          e.preventDefault()
          setInfrastructure(key)
        }}
      >
        <a href='#' className={linkClass ? linkClass : ''}>
          {infrastructureTypes[key]}
        </a>
      </li>
    )
  }

  const connectHiveAside = (
    <div className='aside' data-option='connect-hive'>
      <ol>
        <li>
          <small>Step 1</small>
          <i className='icon thirtytwo'>
            <ClipboardIcon />
          </i>
          <div>
            <h5>Add customer</h5>
            <p>Enter a few details about your customer and their PaperCut Hive organization.</p>
          </div>
        </li>
        <li>
          <small>Step 2</small>
          <i className='icon thirtytwo'>
            <LightningIcon />
          </i>
          <div>
            <h5>Connect customer</h5>
            <p>
              Get your customer to add the PaperCut Multiverse Partner Remote Assistance add-on in their PaperCut Hive
              console.
            </p>
          </div>
        </li>
        <li>
          <small>Step 3</small>
          <i className='icon thirtytwo'>
            <HeroIcon />
          </i>
          <div>
            <h5>Save the day</h5>
            <p>You're ready! Stay on top of things with remote management!</p>
          </div>
        </li>
      </ol>
    </div>
  )

  const provisionHiveAside = (
    <div className='aside' data-option='provision-hive'>
      <ol>
        <li>
          <small>Step 1</small>
          <i className='icon thirtytwo'>
            <PeopleManIcon />
          </i>
          <div>
            <h5>Provision customer</h5>
            <p>Enter a few details about your customer, and kick off provisioning.</p>
          </div>
        </li>
        <li>
          <small>Step 2</small>
          <i className='icon thirtytwo'>
            <MagicIcon />
          </i>
          <div>
            <h5>Weave your magic</h5>
            <p>Apply your expert knowledge and help the customer configure their environment.</p>
          </div>
        </li>
        <li>
          <small>Step 3</small>
          <i className='icon thirtytwo'>
            <HeroIcon />
          </i>
          <div>
            <h5>Save the day</h5>
            <p>You're ready! Stay on top of things with remote management!</p>
          </div>
        </li>
      </ol>
    </div>
  )

  const mfngAside = (
    <div className='aside' data-option='mfng'>
      <ol>
        <li>
          <small>Step 1</small>
          <i className='icon thirtytwo'>
            <ClipboardIcon />
          </i>
          <div>
            <h5>Add customer</h5>
            <p>
              Enter a few details about your customer, and download a unique PaperCut Multiverse Connector installer.
            </p>
          </div>
        </li>
        <li>
          <small>Step 2</small>
          <i className='icon thirtytwo'>
            <LinkCustomerIcon />
          </i>
          <div>
            <h5>Link customer</h5>
            <p>Get your customer to install the Connector on their Application Server.</p>
          </div>
        </li>
        <li>
          <small>Step 3</small>
          <i className='icon thirtytwo'>
            <HeroIcon />
          </i>
          <div>
            <h5>Save the day</h5>
            <p>You're ready! Stay on top of things with remote monitoring and management!</p>
          </div>
        </li>
      </ol>
    </div>
  )

  const onTagInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const tag = e.currentTarget.value
    setNewTag(tag)
    setTagValid(tag.trim().length > 0)
  }

  const mainContent = (
    <form>
      <div className={'field'}>
        <label>Display name</label>
        <input
          ref={nameRef}
          placeholder='Enter organization/account name'
          value={customerName}
          type='text'
          autoFocus={true}
          onChange={e => setCustomerName(e.currentTarget.value)}
        />
      </div>

      <div className={isEditing || product !== Product.Hive ? 'field' : ''}>
        <label>{isEditing ? 'Product' : 'Which product?'}</label>
        <ul className='flex center option-list'>
          <li className={getProductClass(Product.NG)} onClick={e => handleProductChange(e, Product.NG)}>
            <a href='#' className='logo-ng'>
              PaperCut NG
            </a>
          </li>

          <li className={getProductClass(Product.MF)} onClick={e => handleProductChange(e, Product.MF)}>
            <a href='#' className='logo-mf'>
              PaperCut MF
            </a>
          </li>

          <li
            className={`${isEditing ? '' : 'option-list-connected'} ${getProductClass(Product.Hive)}`}
            onClick={e => handleProductChange(e, Product.Hive)}
          >
            <a href='#' className='logo-hive'>
              PaperCut Hive
            </a>
          </li>
        </ul>
      </div>

      <div className={`field ${product === Product.MF || product === Product.NG ? '' : 'hidden'}`}>
        <label>Infrastructure environment</label>

        <ul className='flex center option-list'>
          {infrastructureSelection('aws', 'logo-aws')}

          {infrastructureSelection('azure', 'logo-azure')}

          {infrastructureSelection('gcp', 'logo-gc')}

          {infrastructureSelection('local')}

          <li
            data-option='other'
            className={isOtherInfrastructure ? 'active' : ''}
            onClick={() => setInfrastructure('other')}
          >
            <a href='#'>Other</a>
          </li>
        </ul>

        <div className={infrastructure !== 'other' ? 'hidden' : 'option-other'}>
          <input
            type='text'
            value={otherInfrastructure}
            autoFocus={true}
            onChange={e => setOtherInfrastructure(e.currentTarget.value)}
            placeholder='Enter infrastructure environment'
          />
        </div>
      </div>

      <div className={`option-list-content ${product === Product.Hive && !isEditing ? '' : 'hidden'}`}>
        <div className='field'>
          <label>Create a Hive org instance?</label>

          <ul className='flex center option-list'>
            <li
              data-option='provision-hive'
              className={existingHiveOrg ? '' : 'active'}
              onClick={() => setExistingHiveOrg(false)}
            >
              <a href='#'>
                <i className='icon twentyfour'>
                  <CircledPlusIcon />
                </i>
                <strong>Yes, create new</strong>
              </a>
            </li>

            <li
              data-option='connect-hive'
              className={existingHiveOrg ? 'active' : ''}
              onClick={() => setExistingHiveOrg(true)}
            >
              <a href='#'>
                <i className='icon twentyfour'>
                  <HyperlinkIcon />
                </i>
                <strong>No, connect existing</strong>
              </a>
            </li>
          </ul>
        </div>

        <div className='field btm'>
          <label id='hive-dc'>Data center location</label>

          {!existingHiveOrg && (
            <div className='alert info dip flex-ver'>
              <i className='icon twelve'>
                <InfoIcon />
              </i>
              <div>
                This is where we'll store and process their data. You cannot change the Data center location after you{' '}
                <em>Add customer</em>.
              </div>
            </div>
          )}

          <div className='select fp dip'>
            <select onChange={e => setPmitcDc(e.target.value)} value={pmitcDc} aria-labelledby='hive-dc'>
              {pmitcDcs.map(dc => (
                <option key={dc} value={dc}>
                  {getPmitcDcDisplayName(dc)}
                </option>
              ))}
            </select>
          </div>
        </div>

        {existingHiveOrg && (
          <div className='field'>
            <label id='hive-org'>Org ID</label>
            <input
              type='text'
              placeholder='Enter org instance ID'
              value={hiveOrgId}
              onChange={e => setHiveOrgId(e.target.value.trim())}
            />
          </div>
        )}
        {!existingHiveOrg && (
          <>
            <div className='field'>
              <label>Does this customer have a CRN?</label>
              <ul className='flex center option-list'>
                <li data-option='hive-details' className={hasCrn ? '' : 'active'} onClick={() => setHasCrn(false)}>
                  <a href='#'>
                    <strong>No, enter their details</strong>
                  </a>
                </li>

                <li data-option='hive-crn' className={hasCrn ? 'active' : ''} onClick={() => setHasCrn(true)}>
                  <a href='#'>
                    <strong>Yes, enter the CRN</strong>
                  </a>
                </li>
              </ul>
            </div>

            <div id='hive-crn' className={hasCrn ? 'field btm' : 'field btm hidden'} data-content='hive-crn'>
              <div className='field'>
                <label>CRN</label>
                <div className='alert info dip flex-ver'>
                  <i className='icon twelve'>
                    <InfoIcon />
                  </i>
                  <div>You can find the Customer Reference Number in the PaperCut Sales Portal.</div>
                </div>
                <input
                  type='text'
                  placeholder='C-XXXXXX'
                  value={crn}
                  onChange={e => setCrn(e.currentTarget.value.trim())}
                />
              </div>
            </div>

            {!hasCrn && (
              <div id='hive-details' className='field btm' data-content='hive-details'>
                <h5 className='sep alt'>
                  <span>Customer details</span>
                </h5>

                <div className='alert info dip flex-ver'>
                  <i className='icon twelve'>
                    <InfoIcon />
                  </i>
                  <div>These details will be used to create a CRN in the PaperCut Sales Portal.</div>
                </div>

                <div className='field'>
                  <label>Organization name</label>
                  <label className='inl' htmlFor='display-name'>
                    <input
                      type='checkbox'
                      id='display-name'
                      checked={!hasDifferingNames}
                      onChange={e => setHasDifferingNames(!e.currentTarget.checked)}
                    />
                    Same as <em>Display name</em>
                  </label>
                  {hasDifferingNames && (
                    <input
                      type='text'
                      placeholder='Enter customer organization name'
                      value={hiveOrgName}
                      name='org-new'
                      onChange={e => setHiveOrgName(e.currentTarget.value)}
                    />
                  )}
                </div>

                <div className='field'>
                  <label>Industry</label>

                  {isLoadingOptions && (
                    <div className='loading input'>
                      <LoadingDots />
                    </div>
                  )}
                  {!isLoadingOptions && (
                    <div className='select fp btm'>
                      <select
                        placeholder='Select industry'
                        value={customerIndustry}
                        onChange={e => setCustomerIndustry(e.currentTarget.value)}
                        disabled={industriesList.length === 0}
                      >
                        <option key='unselected'>Select industry</option>
                        {industriesList.map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>

                <div className='field'>
                  <label>Website</label>
                  <input
                    type='text'
                    placeholder="Enter customer's website address"
                    value={customerWebsite}
                    onChange={e => setCustomerWebsite(e.currentTarget.value.trim())}
                  />
                </div>

                <div className='field'>
                  <label>Country</label>

                  {isLoadingOptions && (
                    <div className='loading input'>
                      <LoadingDots />
                    </div>
                  )}
                  {!isLoadingOptions && (
                    <div
                      className={`search autocomplete no-margin-bottom single${
                        isSearchingForCountry && countrySearchTerm.length >= minCountrySearchTermLength
                          ? ' finding'
                          : ''
                      }`}
                    >
                      <div className='entry'>
                        <input
                          type='search'
                          placeholder="Search for customer's country"
                          value={countrySearchTerm}
                          onChange={e => setCountrySearchTerm(e.currentTarget.value)}
                          onFocus={() => setIsSearchingForCountry(true)}
                          onBlur={() => setTimeout(() => setIsSearchingForCountry(false), 250)}
                          disabled={countriesList.length === 0}
                        />
                      </div>
                      <div className='results'>
                        <div className='type'>
                          <ul>
                            {filteredCountries.map(c => (
                              <li key={c.value}>
                                <a href='#' onClick={() => setCountrySearchTerm(c.label)}>
                                  {c.label}
                                </a>
                              </li>
                            ))}
                            {countrySearchTerm.length >= 2 && filteredCountries.length === 0 && (
                              <li key='no-matches'>
                                <span className='not-found'>
                                  <em>No matches found</em>
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className='field'>
              <label className='inl' htmlFor='hive-provisioning-authorized'>
                <input
                  type='checkbox'
                  id='hive-provisioning-authorized'
                  checked={hiveProvisioningAuthorized}
                  onChange={() => setHiveProvisioningAuthorized(!hiveProvisioningAuthorized)}
                />
                The customer has agreed to the{' '}
                <a href='https://hive.papercut.com/terms-of-service' target='_blank'>
                  PaperCut Hive Terms of Service
                </a>
              </label>
            </div>
          </>
        )}
      </div>

      <div className='field margin-top no-margin-bottom'>
        <label>Tags</label>

        <div className='alert info dip flex-ver'>
          <i className='icon twelve'>
            <InfoIcon />
          </i>
          <div>
            Classify customers for easy lookup. You could classify by industry, location or level of support, e.g.{' '}
            <em>education</em>, <em>north</em>, <em>premium</em>.
          </div>
        </div>

        <div className='flex flex-nowrap'>
          <input
            type='text'
            placeholder='Enter classification tag'
            className='tag-new'
            ref={tagInputRef}
            value={newTag}
            onChange={onTagInputChanged}
          />

          <button
            className={`add-tag btn secondary ${tagValid ? '' : 'disabled'}`}
            disabled={!tagValid}
            onClick={addTag}
          >
            Add tag
          </button>
        </div>

        <div className='flex tag-list'>
          {Array.from(tags.values()).map(tag => (
            <div className='tag' key={tag} data-heap-redact-text>
              {tag}

              <a className='lines-button close remove' onClick={() => removeTag(tag)}>
                <span className='lines' />
              </a>
            </div>
          ))}
        </div>
      </div>
    </form>
  )

  const footer = isEditing ? (
    <>
      <button className='btn outline' onClick={onClose}>
        Cancel
      </button>

      <button className='btn' onClick={addCustomer}>
        Save changes
      </button>
    </>
  ) : (
    <a
      className={`btn fp center ${operationInProgress ? 'loading' : ''} ${addCustomerDisabled ? 'disabled' : ''}`}
      onClick={addCustomer}
    >
      <LoadingDots />
      <span className='text'>Add customer</span>
    </a>
  )

  const classNameForAside = () => {
    if (product !== Product.Hive) {
      return 'mfng'
    }

    return existingHiveOrg ? 'connect-hive' : 'provision-hive'
  }

  return props.isVisible ? (
    <CustomModal
      id='popup-customer'
      close={props.close}
      width={isEditing ? '580px' : '780px'}
      parentClass='open-customer'
      additionalClassName={`basic-wide vh ${classNameForAside()}`} // TODO: confirm this css
    >
      <div className='flex flex-nowrap fh'>
        <div className='main'>
          <header className='center'>
            <h3>{isEditing ? 'Edit profile' : 'Add customer'}</h3>
          </header>

          {errors.length > 0 && (
            <div className='alert error'>
              <i className='icon-exclaim'>
                <ExclaimIcon />
              </i>
              <div className='message'>
                <ul>
                  {errors.map(msg => (
                    <li key={msg}>{msg}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className='content'>{mainContent}</div>

          <footer>{footer}</footer>
        </div>

        {!isEditing && (
          <>
            {mfngAside} {connectHiveAside} {provisionHiveAside}
          </>
        )}
      </div>
    </CustomModal>
  ) : (
    <></>
  )
}

export default CustomerModal

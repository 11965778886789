import { AccountDetails } from '../Account/Data'

export type AddCustomerFunction = (product: string, customer: Customer | HiveCustomer) => Promise<boolean>
export type EditCustomerFunction = (product: string, customer: Customer | HiveCustomer, customerId?: string) => Promise<boolean>
export type CustomerFunction = (product: string, customer: Customer | HiveCustomer, customerId?: string) => Promise<boolean>

export interface Customer {
  name: string
  product: string
  infrastructure: string
  tags: Array<string>
}

export interface HiveCustomer extends Customer {
  existingOrg: boolean
  orgId: string
  dataCenter: string
  timezone: string
  crn: string
  customer: {
    name: string
    industry: string
    website: string
    country: string
  }
}

export enum Product {
  Pocket = 'pocket',
  MF = 'mf',
  NG = 'ng',
  Hive = 'hive',
}

export interface ConnectorDownloadURLs {
  customerConnectorDownloadURL: string
  windowsDownloadURL: string
  linuxDownloadURL: string
}

export interface HostData {
  id: string
  name: string
  product: string
  remoteManageLink: string
  infrastructure: string
  tags?: string[]
}

export enum HostEventType {
  Error = 'Error',
  Warning = 'Warning',
  Good = 'Good',
  Remote = 'Remote',
  License = 'License',
  Customer = 'Customer',
}

export interface HostEvent {
  type: HostEventType
  message: string
  occurredAt: Date
}

export enum ConnectionStatus {
  Live,
  Disconnected,
  Disabled,
}

export enum HostStatus {
  Unlinked,
  MonitoringDisabled,
  MaintenanceAndSupportRequired,
  StaleData,
  Good,
}

export class HostDataUtil {
  static getInfrastructureText(infrastructure: string) {
    switch (infrastructure) {
      case 'local':
        return 'Hosted locally'
      case 'azure':
        return 'Hosted on Azure'
      case 'aws':
        return 'Hosted on AWS'
      case 'gcp':
        return 'Hosted on Google Cloud Platform'
      case 'papercut':
        return 'Hosted on PaperCut Cloud Services'
      default:
        return infrastructure ? 'Hosted on ' + infrastructure : 'Hosted locally'
    }
  }

  static getVersion = (systemInfo?: SystemInfoData, accountDetails?: AccountDetails, condensed: boolean = false) => {
    let ver = systemInfo?.version ?? accountDetails?.paperCutVersion ?? ''
    return condensed ? ver.replace(/\(.*\)/, '') : ver
  }

  static newVersionRequired = (version: string) => {
    if (!version) return true
    const majorVersionEndIndex = version.indexOf('.')
    const majorVersion = +version.substring(0, majorVersionEndIndex)
    // only care about the first digit
    const minorPart = +version.substr(majorVersionEndIndex + 1, 1)
    return majorVersion + minorPart / 10 < 19.1
  }

  static getInfrastructureShortName = (infrastructure: string) => {
    switch (infrastructure) {
      case 'aws':
        return 'AWS'
      case 'gcp':
        return 'GCP'
      case 'azure':
        return 'Azure'
      case 'local':
        return 'Local'
      case 'papercut':
        return 'PaperCut'
      default:
        return infrastructure
    }
  }

  static getProductText(product: string) {
    switch (product) {
      default:
        return 'PaperCut ' + product
      case 'pocket':
        return 'PaperCut Pocket'
      case 'mf':
        return 'PaperCut MF'
      case 'ng':
        return 'PaperCut NG'
      case 'hive':
        return 'PaperCut Hive'
    }
  }

  static isPmitcProduct(product: string) {
    return product === Product.Hive || product === Product.Pocket
  }
}

export interface SystemInfoData {
  product: string
  version: string // do not use this directly - use HostDataUtil.getVersion()
  osPlatform: string
  embeddedCount: number
}

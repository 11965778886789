import React, { FunctionComponent, useState } from 'react'
import { MessagesIcon } from '../Icons/Icons'
import { useClickOutside } from '../UseClickOutside/UseClickOutside'
import { Reseller, supportEmail } from './Data'

interface Props {
  contacts: Reseller[]
}

export const ContactSupport: FunctionComponent<Props> = props => {
  const [isOpen, setOpen] = useState(false)
  const ref = useClickOutside(() => setOpen(false))
  const contactsWithEmail = props.contacts.filter(parent => parent.supportEmail !== '')

  return (
    <div ref={ref} id='messages' className={isOpen ? 'open' : ''}>
      {contactsWithEmail.length > 1 && (
        <>
          <div className='flex'>
            <i className='icon twentyfour' onClick={() => setOpen(true)}>
              <MessagesIcon />
            </i>

            <h5>Send an email to:</h5>
          </div>

          <ul onClick={() => setOpen(false)}>
            {props.contacts.map(contact => {
              return (
                <li key={contact.id} data-heap-redact-attributes='href' data-heap-redact-text>
                  <a id={contact.id} title='Send feedback' href={`mailto:${contact.supportEmail}?cc=${supportEmail}`}>
                    {contact.name}
                  </a>
                </li>
              )
            })}
          </ul>
        </>
      )}
      {contactsWithEmail.length <= 1 && (
        <div className='flex' data-heap-redact-attributes='href'>
          <a
            id='feedback'
            title='Send feedback'
            style={{ color: '#fff' }}
            href={`mailto:${contactsWithEmail.length === 0 ? supportEmail : contactsWithEmail[0].supportEmail + '?cc=' + supportEmail}`}
          >
            <i className='icon twentyfour'>
              <MessagesIcon />
            </i>
          </a>
        </div>
      )}
    </div>
  )
}
